// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/no-use-before-define */

import React from 'react';

import styled from '@emotion/styled';

import { BREAKPOINTS, getColorTint, primaryColor, unit } from '@zego/theme';

import H3 from '@zego/components/atoms/H3';
import Spacer from '@zego/components/atoms/Spacer';
import Link from '@zego/components/Link';
import StaticImage from '@zego/components/StaticImage';

interface ArticleProps {
  theme: 'light' | 'dark';
  imageUrl: string;
  imageRatio?: number;
  title: string;
  description?: string;
  link: {
    href: string;
    title: string;
  };
}

const LINK_COLOUR = {
  dark: '#46e18c', // Not yet defined in our theme
  light: primaryColor(9),
};

export default function Article({
  imageUrl,
  imageRatio,
  title,
  description,
  link,
  theme,
}: ArticleProps): JSX.Element {
  const shouldOpenInNewTab = link.href.match(/^http?s:\/\//i);

  return (
    <article
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        justifySelf: 'center',
        maxWidth: unit(48),
        [BREAKPOINTS.normal]: {
          maxWidth: 'auto',
        },
      }}
    >
      <StaticImage
        src={imageUrl}
        ratio={imageRatio || 1}
        css={{ borderRadius: unit(1) }}
      />
      <Spacer height={unit(3)} mobileHeight={unit(2)} />
      <Title>{title}</Title>
      <Spacer height={unit(1)} mobileHeight={unit(2)} />
      {description && (
        <>
          <p css={{ margin: 0, color: getColorTint('grey', 8), fontSize: 16 }}>
            {description}
          </p>
          <Spacer height={unit(2)} css={{ flexGrow: 1 }} />
        </>
      )}
      <Link
        href={link.href}
        css={{ color: LINK_COLOUR[theme], fontSize: 16, fontWeight: 500 }}
        target={shouldOpenInNewTab ? '_blank' : undefined}
      >
        {link.title}
      </Link>
    </article>
  );
}

/**
 * Styled components
 */

const Title = styled(H3)({
  color: getColorTint('grey', 11),
  fontSize: 22,
  lineHeight: '38px',
  [BREAKPOINTS.small]: { lineHeight: '38px' },
  [BREAKPOINTS.medium]: { fontSize: 22, lineHeight: '38px' },
  [BREAKPOINTS.normal]: { lineHeight: '32px' },
});
