// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React from 'react';

import { BREAKPOINTS, unit } from '@zego/theme';

import View from '@zego/components/atoms/View';

export default function CardLayout({ children, ...rest }): JSX.Element {
  return (
    <View
      css={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        rowGap: unit(4),
        width: '100%',
        [BREAKPOINTS.small]: {
          columnGap: unit(4),
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [BREAKPOINTS.normal]: {
          columnGap: unit(8),
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
      }}
      {...rest}
    >
      {children}
    </View>
  );
}
