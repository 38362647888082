// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/no-use-before-define */

import React from 'react';

import styled from '@emotion/styled';

import { BREAKPOINTS, unit } from '@zego/theme';

import View from '@zego/components/atoms/View';
import FlexRow from '@zego/components/molecules/FlexRow';

import StarRating from '@zego/static/svgs/trustpilot-star-rating-4_5.svg';

interface TrustPilotSimpleProps {
  reviews: number;
}

export default function TrustPilotSimple({
  reviews,
}: TrustPilotSimpleProps): JSX.Element {
  let formattedReviews;
  try {
    formattedReviews = Intl.NumberFormat('en-gb').format(reviews);
  } catch (e) {
    console.error(e);
    formattedReviews = reviews;
  }

  return (
    <View
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [BREAKPOINTS.medium]: {
          textAlign: 'left',
          alignItems: 'flex-start',
        },
        [BREAKPOINTS.normal]: {
          margin: '-50px 0 50px 0',
        },
      }}
    >
      <InlineRow>
        <span css={{ marginRight: unit(1), textAlign: 'center' }}>
          Excellent - {formattedReviews} reviews on Trustpilot
        </span>
      </InlineRow>
      <img src={StarRating} alt="4.5 stars" height={16} />
    </View>
  );
}

/**
 * Styled components
 */

const InlineRow = styled(FlexRow)({
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: 8,
  [BREAKPOINTS.medium]: {
    justifyContent: 'flex-start',
  },
});
